// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-apply-old-jsx": () => import("./../../../src/pages/apply-old.jsx" /* webpackChunkName: "component---src-pages-apply-old-jsx" */),
  "component---src-pages-challenge-[id]-jsx": () => import("./../../../src/pages/challenge/[id].jsx" /* webpackChunkName: "component---src-pages-challenge-[id]-jsx" */),
  "component---src-pages-dashboard-jsx": () => import("./../../../src/pages/dashboard.jsx" /* webpackChunkName: "component---src-pages-dashboard-jsx" */),
  "component---src-pages-grades-jsx": () => import("./../../../src/pages/grades.jsx" /* webpackChunkName: "component---src-pages-grades-jsx" */),
  "component---src-pages-portfolio-jsx": () => import("./../../../src/pages/portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-jsx" */),
  "component---src-pages-profile-jsx": () => import("./../../../src/pages/profile.jsx" /* webpackChunkName: "component---src-pages-profile-jsx" */),
  "component---src-pages-project-jsx": () => import("./../../../src/pages/project.jsx" /* webpackChunkName: "component---src-pages-project-jsx" */),
  "component---src-pages-reset-jsx": () => import("./../../../src/pages/reset.jsx" /* webpackChunkName: "component---src-pages-reset-jsx" */),
  "component---src-pages-student-jsx": () => import("./../../../src/pages/student.jsx" /* webpackChunkName: "component---src-pages-student-jsx" */),
  "component---src-pages-student-subscription-jsx": () => import("./../../../src/pages/student-subscription.jsx" /* webpackChunkName: "component---src-pages-student-subscription-jsx" */),
  "component---src-pages-students-jsx": () => import("./../../../src/pages/students.jsx" /* webpackChunkName: "component---src-pages-students-jsx" */),
  "component---src-pages-subscription-jsx": () => import("./../../../src/pages/subscription.jsx" /* webpackChunkName: "component---src-pages-subscription-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-test-jsx": () => import("./../../../src/pages/test.jsx" /* webpackChunkName: "component---src-pages-test-jsx" */),
  "component---src-pages-update-subscription-jsx": () => import("./../../../src/pages/update-subscription.jsx" /* webpackChunkName: "component---src-pages-update-subscription-jsx" */),
  "component---src-pages-verify-jsx": () => import("./../../../src/pages/verify.jsx" /* webpackChunkName: "component---src-pages-verify-jsx" */),
  "component---src-templates-about-jsx": () => import("./../../../src/templates/about.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-blog-inner-jsx": () => import("./../../../src/templates/blog-inner.jsx" /* webpackChunkName: "component---src-templates-blog-inner-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-challenge-apply-jsx": () => import("./../../../src/templates/challengeApply.jsx" /* webpackChunkName: "component---src-templates-challenge-apply-jsx" */),
  "component---src-templates-challenge-list-jsx": () => import("./../../../src/templates/challenge-list.jsx" /* webpackChunkName: "component---src-templates-challenge-list-jsx" */),
  "component---src-templates-challenge-submission-jsx": () => import("./../../../src/templates/challengeSubmission.jsx" /* webpackChunkName: "component---src-templates-challenge-submission-jsx" */),
  "component---src-templates-community-jsx": () => import("./../../../src/templates/community.jsx" /* webpackChunkName: "component---src-templates-community-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-product-inner-jsx": () => import("./../../../src/templates/product-inner.jsx" /* webpackChunkName: "component---src-templates-product-inner-jsx" */),
  "component---src-templates-program-details-jsx": () => import("./../../../src/templates/program-details.jsx" /* webpackChunkName: "component---src-templates-program-details-jsx" */),
  "component---src-templates-program-jsx": () => import("./../../../src/templates/program.jsx" /* webpackChunkName: "component---src-templates-program-jsx" */)
}

